<template>
    <b-card no-body>
        <div class="modal-body">
            <b-table-simple fixed>
                <b-thead head-variant="dark">
                    <b-tr>
                        <b-th>Название подразделения</b-th>
                        <b-th>Цеха</b-th>
                        <b-th>Использовать в табеле</b-th>
                        <b-th>Дата начала</b-th>
                        <b-th>Дата окончания</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-th>
                            <b-form-input :disabled="selectedDepartment.hasOwnProperty('id')" v-model="selectedDepartment.name" placeholder="Название подразделения"></b-form-input>
                        </b-th>
                        <b-th>
                            <storage-select v-model="selectedDepartment.storages" open-direction="bottom"></storage-select>
                        </b-th>
                        <b-th>
                            <b-checkbox v-model="selectedDepartment.salary_enabled"></b-checkbox>
                        </b-th>
                        <b-th>
                            <date-picker
                                lang="ru"
                                type="month"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата от"
                                v-model="selectedDepartment.date_from"
                                key="selectedDepartment.date_from"
                                id="selectedDepartment.date_from"
                            />
                        </b-th>
                        <b-th>
                            <date-picker
                                :disabled="selectedDepartment.date_from === null"
                                :disabled-date="disableDateToDates"
                                :class="dateToLessThenToday() ? 'dateToLessThenToday' : ''"
                                lang="ru"
                                type="month"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата до"
                                v-model="selectedDepartment.date_to"
                                key="selectedDepartment.date_to"
                                id="selectedDepartment.date_to"
                            />
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <div class="modal-footer">
            <b-button-group>
                <b-button variant="success" @click="save">Сохранить</b-button>
            </b-button-group>
        </div>
    </b-card>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {cloneDeep} from 'lodash';
import StorageSelect from "@components/_common/StorageSelect";
import {DEPARTMENT_ENDPOINT} from "@utils/endpoints";
import moment from 'moment';

export default {
    name: "DepartmentForm",
    components: {
        DatePicker,
        StorageSelect,
    },
    props: {
        department: {
            type: Object,
            default: () => {
                return {
                    current_responsible_user: null,
                    salary_enabled: null,
                    name: null,
                    date_from: null,
                    date_to: null,
                    storages: [],
                }
            }
        }
    },
    data() {
        return {
            selectedDepartment: cloneDeep(this.department),
        }
    },
    methods: {
        dateToLessThenToday() {
            return this.selectedDepartment.date_to && moment(this.selectedDepartment.date_to).endOf('month').isBefore(moment())
        },
        disableDateToDates(date) {
            const dateFrom = new Date(this.selectedDepartment.date_from)

            return date < new Date().setDate(dateFrom.getMonth() - 1);
        },
        save() {
            let result = true;
            if (
                (this.selectedDepartment.date_from !== null && this.selectedDepartment.date_from !== this.department.date_from)
                || (this.selectedDepartment.date_to !== null && this.selectedDepartment.date_to !== this.department.date_to)
            ) {
                result = confirm('Вы уверены что хотите поменять даты подразделения? Это может повлиять на зрплаты.');
            }

            if (!result) {
                return;
            }

            const params = {
                ...this.selectedDepartment,
                date_from: this.selectedDepartment.date_from,
                date_to: this.selectedDepartment.date_to,
                storage_ids: this.selectedDepartment.storages && this.selectedDepartment.storages.map(el => el.id) || null,
            }

            const method = this.selectedDepartment.id ? 'put' : 'post';
            const url = this.selectedDepartment.id ? DEPARTMENT_ENDPOINT + '/' + this.selectedDepartment.id : DEPARTMENT_ENDPOINT;

            this.$http[method](url, params)
                .then(response => {
                    if (response.body.success) {
                        this.$toast.show('Сохранено')
                    } else {
                        this.$toast.error('Не удалось обновить подразделения')
                        this.$root.responseError(response)
                    }
                })
                .catch(this.$root.responseError)
        }
    }
}
</script>
<style scoped>
    .dateToLessThenToday {
        border: 1px solid red;
        border-radius: 5px;
    }
</style>
