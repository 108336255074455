<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="3">
                    <b-button
                        @click="create"
                        variant="success"
                    >
                        <b-icon-plus scale="1.2"/>
                    </b-button>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="fetchItems" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters">
                <div class="card-body">
                    <b-row>
                        <b-col md="3">
                            <date-picker
                                lang="ru"
                                type="month"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата от"
                                v-model="filters.date_from"
                                key="filters.dateFrom"
                                id="filters.dateFrom"
                            />
                        </b-col>
                        <b-col md="3">
                            <date-picker
                                lang="ru"
                                type="month"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата до"
                                v-model="filters.date_to"
                                key="filters.dateTo"
                                id="filters.dateTo"
                            />
                        </b-col>
                        <b-col md="3">
                            <b-form-select v-model="filters.salary_enabled" :options="salary_enabled_options" />
                        </b-col>
                        <b-col md="3">
                            <department-picker v-model="filters.department"></department-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <user-input v-model="filters.responsible_user"></user-input>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>

        <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
            head-variant="dark"
        >
            <template #cell(responsible_user)="data">
                <span>{{
                        data.item.current_responsible_user && data.item.current_responsible_user.fullname || ''
                    }}</span>
            </template>

            <template #cell(responsible_user_date_from)="data">
                <span>{{
                        data.item.current_responsible_user && data.item.current_responsible_user.pivot.date_from || ''
                    }}</span>
            </template>

            <template #cell(responsible_user_date_to)="data">
                <span>{{
                        data.item.current_responsible_user && data.item.current_responsible_user.pivot.date_to || ''
                    }}</span>
            </template>

            <template #cell(salary_enabled)="data">
                <b-form-checkbox disabled :checked="data.item.salary_enabled" />
            </template>

            <template #cell(actions)="data">
                <b-button-group size="sm">
                    <b-button @click="changeDepartment(data.item.id)" v-b-tooltip.hover
                              title="Изменить действие подразделений" variant="warning">
                        <b-icon-box-arrow-up-left/>
                    </b-button>
                    <b-button @click="changeResponsibleUser(data.item.id)" v-b-tooltip.hover
                              title="Изменить ответственного" variant="primary">
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button @click="deleteDepartment(data.item.id)" v-b-tooltip.hover title="Удалить"
                              :disabled="!data.item.can_delete"
                              variant="danger">
                        <b-icon-x/>
                    </b-button>
                </b-button-group>
            </template>

            <template #cell(storages)="data">
                <span>{{ data.item.storages.map(el => el.name).join(', ') }}</span>
            </template>
        </b-table>
    </div>
</template>

<script>
import {DEPARTMENT_ENDPOINT} from "@utils/endpoints";
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import lsfMixin from "@utils/localStorageFilterMixin";
import UserInput from "@components/_common/UserInput";
import DepartmentSetResponsibleUsersModal from "@components/department/DepartmentSetResponsibleUsersModal";
import DepartmentForm from "@components/department/DepartmentForm";
import DepartmentPicker from "@components/_common/DepartmentPicker";

const FILTERS_PATTERN = {
    date_from: null,
    date_to: null,
    responsible_user: null,
    names: [],
    salary_enabled: null,
};
const DETAILS_MODAL_PARAMS = {
    width: 1400,
    height: 'auto',
    adaptive: true,
    scrollable: true
}

export default {
    name: "DepartmentList",
    mixins: [lsfMixin],
    components: {
        DatePicker,
        UserInput,
        DepartmentPicker,
    },
    data() {
        return {
            selectedDepartment: null,
            filters: {
                date_from: null,
                date_to: null,
                responsible_user: null,
                names: [],
                salary_enabled: null,
            },
            salary_enabled_options: [
                {value: null, text: 'Все'},
                {value: true, text: 'Используется в табеле'},
                {value: false, text: 'Не используется в табеле'},
            ],
            fields: [
                {key: 'id', label: '№'},
                {key: 'name', label: 'Название подразделения'},
                {key: 'storages', label: 'Цеха'},
                {key: 'salary_enabled', label: 'Используется в табеле'},
                {key: 'date_from', label: 'Дата начала'},
                {key: 'date_to', label: 'Дата окончания'},
                {key: 'responsible_user', label: 'Ответственный'},
                {key: 'responsible_user_date_from', label: 'Начало ответственности'},
                {key: 'responsible_user_date_to', label: 'Окончание ответственности'},
                {key: 'actions', label: 'Действия'},
            ],
            items: [],
        }
    },
    methods: {
        deleteDepartment(departmentId) {
            this.$http
                .delete(DEPARTMENT_ENDPOINT + '/' + departmentId)
                .then(response => {
                    if (response.body.success) {
                        this.fetchItems()
                    } else {
                        this.$toast.error('Не удалось загрузить подразделения')
                        this.$root.responseError(response)
                    }
                })
                .catch(this.$root.responseError)
        },
        create() {
            this.$modal.show(DepartmentForm, {}, DETAILS_MODAL_PARAMS);
        },
        changeDepartment(departmentId) {
            this.selectedDepartment = this.items.find(el => el.id === departmentId);
            this.$modal.show(DepartmentForm, {department: this.selectedDepartment}, DETAILS_MODAL_PARAMS, {
                closed: () => {
                    this.selectedDepartment = null
                    this.fetchItems()
                }
            });
        },
        changeResponsibleUser(departmentId) {
            this.selectedDepartment = this.items.find(el => el.id === departmentId);
            this.$modal.show(DepartmentSetResponsibleUsersModal, {department: this.selectedDepartment}, DETAILS_MODAL_PARAMS, {
                closed: () => {
                    this.selectedDepartment = null
                    this.fetchItems()
                }
            });
        },
        dateToPassed(dateTo) {
            if (dateTo === null) return '';

            return moment(dateTo).isBefore(moment()) ? '.date-to-passed' : '';
        },
        fetchItems() {
            this.items = [];

            const params = {
                filters: {
                    ...this.filters,
                    name: this.filters.department?.name,
                    responsible_user_ids: [this.filters?.responsible_user?.id],
                    salary_enabled: this.filters?.salary_enabled
                },
                limit: 99,
                appends: [
                    'current_responsible_user',
                ],
                orders: {name: 'asc', date_to: 'asc'},
                with: ['responsibleUsers', 'storages']
            };

            this.$http
                .get(DEPARTMENT_ENDPOINT, {params})
                .then(response => {
                    if (response.body.success) {
                        this.items = response.data.data.data;
                    } else {
                        this.$toast.error('Не удалось загрузить подразделения')
                        this.$root.responseError(response)
                    }
                })
                .catch(this.$root.responseError)
        }
    },
    created() {
        this.fetchItems();
        this.initFilters(this, FILTERS_PATTERN)
    }
}
</script>
