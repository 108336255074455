<template>
    <b-card no-body>
        <div class="modal-body">
            <b-table-simple striped>
                <b-thead head-variant="dark">
                    <b-tr>
                        <b-th>Пользователь</b-th>
                        <b-th>Дата начала ответственности</b-th>
                        <b-th>Дата окончания ответственности</b-th>
                        <b-th>Действия</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <template
                        v-if="selectedDepartment.responsibleUsers && selectedDepartment.responsibleUsers.length > 0">
                        <b-tr v-for="responsibleUser in selectedDepartment.responsibleUsers">
                            <b-th>{{ responsibleUser.fullname }}</b-th>
                            <b-th>
                                <date-picker
                                    lang="ru"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Дата от"
                                    v-model="responsibleUser.pivot.date_from"
                                    key="responsibleUser.pivot.date_from"
                                    id="responsibleUser.pivot.date_from"
                                />
                            </b-th>
                            <b-th>
                                <date-picker
                                    :disabled="responsibleUser.pivot.date_from === null"
                                    lang="ru"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Дата до"
                                    :class="dateToLessThenToday(responsibleUser.pivot.date_to) ? 'dateToLessThenToday' : ''"
                                    v-model="responsibleUser.pivot.date_to"
                                    key="responsibleUser.pivot.date_to"
                                    id="responsibleUser.pivot.date_to"
                                />
                            </b-th>
                            <b-th>
                                <b-button-group>
                                    <b-button variant="danger" @click="deleteResponsibleUser(responsibleUser)">Удалить
                                    </b-button>
                                </b-button-group>
                            </b-th>
                        </b-tr>
                    </template>
                    <template v-else>
                        <b-tr>
                            <b-th colspan="4">
                                Пока что ничего нет
                            </b-th>
                        </b-tr>
                    </template>
                    <b-tr>
                        <b-th>
                            <user-input v-model="newResponsibleUser.user"></user-input>
                        </b-th>
                        <b-th>
                            <date-picker
                                :disabled="!newResponsibleUser"
                                lang="ru"
                                type="date"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата от"
                                v-model="newResponsibleUser.date_from"
                            />
                        </b-th>
                        <b-th>
                            <date-picker
                                :disabled="!newResponsibleUser || newResponsibleUser.date_from === null"
                                :disabled-date="disableDateToDates"
                                lang="ru"
                                type="date"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата от"
                                v-model="newResponsibleUser.date_to"
                            />
                        </b-th>
                        <b-th>
                            <b-button :disabled="!newResponsibleUser.user" @click="add" variant="success">+</b-button>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <div class="modal-footer">
            <b-button variant="success" @click="save">Сохранить</b-button>
        </div>
    </b-card>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import DatePicker from 'vue2-datepicker';
import UserInput from "@components/_common/UserInput";
import {DEPARTMENT_ENDPOINT} from "@utils/endpoints";
import moment from "moment";

export default {
    name: "DepartmentSetResponsibleUsersModal",
    props: {
        department: {
            type: Object
        }
    },
    components: {
        DatePicker,
        UserInput,
    },
    data() {
        return {
            selectedDepartment: null,
            newResponsibleUser: {
                user: null,
                date_from: null,
                date_to: null,
            },
        }
    },
    methods: {
        dateToLessThenToday(dateTo) {
            return dateTo && moment(dateTo).isBefore(moment())
        },
        disableDateToDates(date) {
            const dateFrom = new Date(this.newResponsibleUser.date_from)

            return date < new Date().setDate(dateFrom.getDate() - 1);
        },
        add() {
            this.selectedDepartment.responsibleUsers = [...this.selectedDepartment.responsibleUsers, {
                ...this.newResponsibleUser.user,
                pivot: {
                    date_from: this.newResponsibleUser.date_from,
                    date_to: this.newResponsibleUser.date_to,
                    department_id: this.selectedDepartment.id,
                    user_id: this.newResponsibleUser.user.id
                }
            }]

            this.newResponsibleUser = {};
        },
        deleteResponsibleUser(responsibleUser) {
            const responsibleUsers = this.selectedDepartment.responsibleUsers
                .filter(el => el.pivot.user_id !== responsibleUser.pivot.user_id
                    && el.pivot.date_from !== responsibleUser.pivot.date_from)

            this.$set(this.selectedDepartment, 'responsibleUsers', responsibleUsers)
        },
        save() {
            const params = this.selectedDepartment.responsibleUsers
                ? this.selectedDepartment.responsibleUsers.reduce((acc, item) => {
                    acc = [...acc, {
                        user_id: item.id,
                        date_from: item.pivot.date_from,
                        date_to: item.pivot.date_to,
                        department_id: item.pivot.department_id,
                    }]

                    return acc
                }, [])
                : []

            this.$http
                .post(DEPARTMENT_ENDPOINT + '/' + this.selectedDepartment.id + '/set-responsible-users',
                    {responsible_users: params})
                .then(response => {
                    if (response.body.success) {
                        this.$toast.show('Сохранено')
                    } else {
                        this.$toast.error('Не удалось назначить ответсвенных')
                        this.$root.responseError(response)
                    }
                })
                .catch(this.$root.responseError)
        }
    },
    created() {
        this.selectedDepartment = cloneDeep(this.department);
    }
}
</script>

<style scoped>
 .dateToLessThenToday {
     border: 1px solid red;
     border-radius: 5px;
 }
</style>
